import styled from "styled-components";
import { Color, ColorDark, Font, breakpoints } from "@/shared/Constants";

export const NormalArticle = styled.article`
  position: relative;
  display: flex;
  justify-content: center;
  .contentVideo {
    height: inherit;
    p.epigraphe {
      display: none !important;
    }
    > div {
      height: inherit !important;
    }
    .jwplayer.jw-stretch-uniform .jw-media video {
      object-fit: cover !important;
    }
  }
  .contentVideo {
    .epigraphe {
      display: none;
    }
  }
  .mt {
    display: flex;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;
    h2.title {
      ${Font.clarinVarSub2Bold};
      color: ${Color.black};
      order: 2;
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          color: ${ColorDark.textLight};
        }
      }
    }
    .date {
      ${Font.sansRegular};
      color: ${Color.mainColor};
      font-size: 11px;
      min-height: 32px;
      display: inline-block;

      &:after {
        content: "";
        width: 25px;
        height: 1px;
        display: block;
        background: ${Color.mainColor};
        margin: 10px 0;
      }
    }
    .volanta {
      ${Font.clarinVarSub2Regular};
      color: ${Color.mainColor};
      order: 1;
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          color: ${ColorDark.mainColor};
        }
      }
    }
    .bottomSummary {
      order: 4;
      margin-bottom: 15px;
      ${breakpoints.phone} {
        margin: 15px 0;
      }
    }
    .bajada {
      font-size: 15px;
      line-height: 21px;
      color: ${Color.grey};
      ${Font.sansRegular};
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          color: ${ColorDark.textLight};
        }
      }
      ${breakpoints.phone} {
        font-size: 16px;
        line-height: 22px;
      }
    }
    .data-txt {
      order: 4;
      display: flex;
      align-items: center;
      gap: 6px;
      > p.name {
        font-size: 11.5px;
        line-height: 11px;
        ${Font.sansRegular};
        color: ${Color.grey};
        text-transform: uppercase;
        ${breakpoints.darkMode} {
          [data-theme="dark"] & {
            color: ${ColorDark.textLight};
          }
        }
      }
    }
  }
  picture,
  video,
  .contentVideo {
    display: block;
    position: relative;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    p.epigraphe {
      ${Font.sansSemiBold};
      font-size: 15px;
      line-height: 20px;
      position: absolute;
      bottom: 15px;
      width: 74%;
      text-align: center;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      display: block;
      color: #fff;
      left: 0;
      right: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          color: ${ColorDark.textLight};
        }
      }
    }
  }
  /* .contentVideo {
    display: block;
    position: relative;
    height:auto;
    object-fit: cover;
    ${breakpoints.phone} {
      min-height: 340px;
    }
    video {
      ${breakpoints.phone} {
        min-height: 340px;
        position:relative;
      }
      height: 100%;
      object-fit: cover;
      
    }
      ${breakpoints.phone} {
        width:100%;
        min-height: 340px;
      }
      
      
  } */
  /* > div {
      padding-bottom:20px;
    } */
`;
