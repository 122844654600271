import styled from "styled-components";
import { backBanner, breakpoints } from "@/shared/Constants";

export const ContetBanner = styled.div`
    &#div-gpt-ad-horizontal1{
        margin-bottom:0;
        max-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        ${breakpoints.mobileFirst} {
            min-height: 200px;
        }
    }
`
export const DummyBanner = styled.div`
    overflow: hidden;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 300px;
    width: 300px;
    ${backBanner}

`