import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";

export const StreamingBox = styled.div`
  position: relative;
  background: ${Color.grey};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: 148px;
  transition: all 0.2s ease;
  z-index: 4;
  ${breakpoints.phone} {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
  .live-for {
    display: flex;
    ${breakpoints.phone} {
      flex-direction: column;
    }
    .live-content {
      display: flex;
    }
  }
  .contentIframe {
    width: 307px;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    ${breakpoints.phone} {
      width: 100%;
      height: auto;
    }
    button.iframe-bt {
      display: none;
    }
    button.iframe-bt.collapsed {
      width: 150px;
      height: 100%;
      z-index: 2;
      position: absolute;
      opacity: 0;
      cursor: pointer;
      display: block;
    }
  }

  .live-bt {
    display: none;
  }

  .live-iframe {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 149px;
      width: 100%;
      ${breakpoints.phone} {
        height: 100%;
      }
    }
  }

  /* //streaming sin Colapsar chico */
  &.collapsed {
    height: 73px;
    ${breakpoints.phone} {
      height: 205px;
      display: flex;
      flex-direction: row;
      padding-top: 15px;
    }
    .live-frizo {
      ${breakpoints.phone} {
        width: 105px;
        height: 22px;
        display: flex;
        align-items: center;
      }
    }
    .live-for {
      display: flex;
      ${breakpoints.phone} {
        flex-direction: column;
      }
      .live-content {
        display: flex;
        align-items: revert;
      }
    }
    ${breakpoints.phone} {
      .live-content {
        display: flex;
        flex-direction: row;
        /* height:95px; */
      }
    }
    .live-mt {
      ${breakpoints.phone} {
        padding: 12px 0;
        max-width: 85%;
      }
      h2 {
        -webkit-line-clamp: 1;
        ${breakpoints.phone} {
          -webkit-line-clamp: 3;
        }
      }
    }
    .contentIframe {
      width: 150px;
      flex-grow: 0;
      flex-shrink: 0;
    }
    .live-bt {
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      width: 65px;
      background: ${Color.mainColor};
      border: 0 none;
      cursor: pointer;
      &:hover {
        background: #c2021b;
      }
      p {
        font-size: 14px;
        color: ${Color.white};
        ${Font.sansSemiBold};
      }
    }
    .live-03-bt {
      display: none;
    }
  }
`;

export const LiveDot = styled.span`
  font-size: 16px;
  color: ${Color.grey};
  ${Font.sansBold};
  white-space: nowrap;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    height: 6px;
    width: 6px;
    background-color: ${Color.mainColor};
    border-radius: 50%;
    display: inline-block;
    border: 3px solid ${Color.mainColor};
    margin-right: 8px;
    margin-top: -2px;

    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        background-color: ${ColorDark.darkGrey};
      }
    }
  }
`;
