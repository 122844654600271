import { StreamingBox } from "./styles";
import { useState } from "react";

const Streaming = (props) => {
  const { autoplay, link, mute, expanded } = props;

  const isExpanded = expanded ? (expanded == "1" ? true : false) : true;
  const [show, setShow] = useState(isExpanded);
  const isAutoplay = autoplay && autoplay == "1" ? "1" : "0";
  const isMute = mute && mute == "1" ? "1" : "0";
  const url = `${link}${
    link.includes("?") ? "&" : "?"
  }autoplay=${isAutoplay}&mute=${isMute}`;

  return (
    <StreamingBox className={`streaming-box ${!show ? "collapsed" : ""}`}>
      <div className="live-for">
        <div className="live-content">
          <button
            className="live-bt"
            type="button"
            onClick={() => {
              setShow(!show);
            }}
          >
            <p>VER</p>
          </button>
          <div className="contentIframe">
            <button
              className={` iframe-bt ${!show ? "collapsed" : ""}`}
              type="button"
              onClick={() => {
                setShow(!show);
              }}
            ></button>
            <div className="live-iframe">
              <iframe
                width="560"
                height="315"
                src={url}
                //title={title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
    </StreamingBox>
  );
};

export default Streaming;
